.file-upload .drop-zone label {
  cursor: pointer;
}

.file-upload .drop-zone input[type="file"] {
  display: none;
}

.file-upload .overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 0;
  overflow: hidden;
  z-index: 999999;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.file-upload .overlay:after {
  display: block;
  position: absolute;
  content: "";
  top: 0.5em;
  left: 0.5em;
  right: 0.5em;
  bottom: 0.5em;
  border: 0.2em dashed rgba(255, 255, 255, 0.4);
  border-radius: 1em;
}

.file-upload .overlay.active {
  height: 100%;
  opacity: 1;
}

.file-upload .overlay * {
  pointer-events: none;
}
.file-upload .overlay__inner {
  width: 30%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 10px;
  padding: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 2.5s ease;
  text-align: center;
}

.file-upload .overlay__inner p {
  color: #fff;
  font-size: 3em;
}

.file-upload .overlay.active .overlay__inner {
  opacity: 1;
  width: 30%;
  height: 50%;
}
